import * as React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';


export const CustomSubmitButton = () => {
    return (
        <center>
          <Button 
            variant="contained"
            color="primary"
            type="submit"
          >
            Atualizar Valor
          </Button>
        </center>
    );
  };
  