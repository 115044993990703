import * as React from 'react';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';


export const CustomFooter = () => {
    return (
      <center>
        <Typography variant="caption" gutterBottom component="div">
          Fonte de dados: Banco Central do Brasil.
          <br/>
          Obs.: Os valores inseridos para correção são sempre considerados em real. A correção é sempre realizada utilizando-se referências mensais.
        </Typography>
        <Typography component="a" href="mailto:contato@atualizarvalor.com.br" style={{ fontSize: '0.69rem' }}>
          <MailIcon/>
          &nbsp;
          contato@atualizarvalor.com.br
        </Typography>
      </center>
    );
  };
  