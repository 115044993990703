import * as React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const navigator = window.navigator;


export const CustomLinkButton = ({values, valor, taxa, initial_date, final_date}) => {
  const initial_date_string = initial_date.toISOString();
  const final_date_string = final_date.toISOString();
  return (
    <Tooltip title="Copiar link para clipboard">
      <Button variant="outlined" color="primary" onClick={() => {navigator.clipboard.writeText(window.location.origin + "?valor="+valor+"&taxa="+taxa+"&data_inicio="+initial_date_string+"&data_fim="+final_date_string)}}>
        <LinkIcon/>
        &nbsp;
        Gerar Link
      </Button>
    </Tooltip>
  );
};