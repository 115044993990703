import * as React from 'react';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { CustomInitialInfo } from './CustomInitialInfo.js';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


export const CustomInitialInfoModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <IconButton
        onClick={handleOpen}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
      >
        <HelpOutlineIcon />
      </IconButton>
      <Modal
        keepMounted 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomInitialInfo/>
      </Modal>
    </div>
  );
};
