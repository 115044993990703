import * as React from 'react';
import PropTypes from 'prop-types';
//import TextField from '@mui/material/TextField';
import {
  TextField
} from 'mui-rff';
import NumberFormat from 'react-number-format';


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString
      prefix="R$"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};




export const MaskedValorTextField = (props) => {
  return (
    <TextField
      label={props.label}
      name={props.id}
      variant="outlined" 
      id={props.id}
      size="small"
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      helperText={props.helperText}
    />
  );
}