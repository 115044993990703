import * as React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const navigator = window.navigator;

export const CustomShareButton = ({values, valor_atualizado_final, valor, taxa, initial_date, final_date}) => {
  const initial_date_string = initial_date.toLocaleDateString();
  const final_date_string = final_date.toLocaleDateString();
  //const valor_atualizado_arredondado = Math.round( ( valor_atualizado_final + Number.EPSILON ) * 100 ) / 100
  const valor_atualizado_arredondado = parseFloat(valor_atualizado_final).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const shareData = {
    title: 'Corrige Valor',
    text: 'R$ ' +valor+ ' corrigido por ' + taxa + ' de '+initial_date_string+' a '+final_date_string+': *R$'+valor_atualizado_arredondado+'*.',
    //text: '*R$ ' + valor_atualizado_final + '*. R$ '+valor+' corrigido por '+taxa+' de '+initial_date_string+' a '+final_date_string+': *R$'+valor_atualizado_final+'*.',
    url: window.location.origin + "?valor="+valor+"&taxa="+taxa+"&data_inicio="+initial_date_string+"&data_fim="+final_date_string,
  }
  //console.log("FLASDKFASDLKFASD");
  //console.log(shareData);
    return (
      <Tooltip title="Compartilhar Link">
        <Button variant="outlined" color="primary" onClick={() => {navigator.share(shareData)}}>
          <LinkIcon/>
          &nbsp;
          Compartilhar
        </Button>
      </Tooltip>
    );
  };
//<Button variant="outlined" color="primary" onClick={() => {navigator.clipboard.writeText()}}>