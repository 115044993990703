import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer } from 'recharts';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



const CustomSmallTooltip = ({ payload, label }) => {
 //console.log("SMALL TOOLTIPS") ;
  //if (active && payload && payload.length) {
    //console.log(payload);
    //console.log(main_element)
    return (
      <Card sx={{ minWidth: 100 }} style={{
        backgroundColor: 'rgba(33,33,33,1)',
        opacity: 0.75}}
      >
        <CardContent>
          <Typography variant="body2" style={{color: '#FFFFFF'}}>{`${label}`}</Typography>
          {payload.map((item, index) => {
            return (
              <Typography key={item.name} variant="body2" color={item.stroke} component="div">
                {`${item.unit} ${parseFloat(item.value).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
              </Typography>
            );
          })}
        </CardContent>
      </Card>
    );
  //}

  //return null;
};




export const FormatedAreaChart = ({ graph, main_element }) => {
  var valor = 0;
  if (! graph.data) {
    return null;
  }
  if (! graph.data[0] && ! graph.data[1]) {
    return null;
  }
  if (graph.data[1]) {
    valor = graph.data[1].valor;
  } else {
    valor = graph.data[0].valor;
  }
  return (
    <div className="ResponsiveContainerParent" style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer key={valor}>
        <AreaChart
          data={graph.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3182BD" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#3182BD" stopOpacity={0.0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="data_formatada"/>
          <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short" }).format(value)}/>
          <Tooltip content={<CustomSmallTooltip main_element={main_element}/>}/>
          <Area  key={main_element} stroke="#3182BD" fillOpacity={1} fill="url(#colorValue)" type="monotone" dataKey="valor" dot={false} unit="R$" name={main_element}/>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
};