import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 200,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const CustomInitialInfo = () => {
    return (
      <Box sx={style} >
        <Typography id="server-modal-title" variant="h6" component="h2">
        Para atualizar o valor desejado
        </Typography>
        <Typography variant="body2" gutterBottom component="div">
        <ul>
          <li>Digitar o valor a ser corrigido.</li>
          <li>Selecionar o índice de correção (IPCA, IGP-M, INPC, SELIC, TR, CDI).</li>
          <li>Selecionar uma data de início e uma data de fim.</li>
          <li>Clicar em "ATUALIZAR VALOR".</li>
        </ul>
      </Typography>
    </Box>
    );
  };
  