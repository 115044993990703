import React, { Component } from 'react';
import { CustomForm } from './form.js';



/*VALORES PADROES*/
/*VALORES PADROES*/
/*VALORES PADROES*/
const urlParams = new URLSearchParams(window.location.search);
const param_valor = urlParams.get('valor');
const param_taxa = urlParams.get('taxa');
const param_initial_date = urlParams.get('data_inicio');

const param_final_date = urlParams.get('data_fim');

var record = new Object();
record.value        = 1000.42;
record.taxa         = "IPCA";
record.initial_date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));


//Nao precisva ter 2 loads do JSON.... Poderia te ro load só aqui e passar como parâmetro pro form... Mas vou manter assim por enquanto.
import taxas from './taxas.json';
let taxas_originais = JSON.parse(JSON.stringify(taxas));
record.final_date   = new Date(taxas_originais[taxas_originais.length - 1].Data);

if (param_valor && /^[0-9]+\.?([0-9]+)?$/.test(param_valor)) {
    record.value = param_valor;
}
if (param_taxa && /^[A-Z0-9]+$/.test(param_taxa)) {
    record.taxa = param_taxa;
}

if (param_initial_date && /^[a-zA-Z0-9\-:\s\.]{1,42}$/.test(param_initial_date)) {
//if (param_initial_date) {
    //console.log("DATA ANTES");
    //console.log(param_initial_date);
    record.initial_date = new Date(param_initial_date);
    //console.log("DATA INICIO");
    //console.log(record.initial_date);
}


if (param_final_date   && /^[a-zA-Z0-9\-:\s\.]{1,42}$/.test(param_final_date)) {
/*
if (param_final_date   && /^[0-9]{4}\-(0[1-9]|1[0-2])\-([0-9]|1[0-9]|2[0-9]|3[0-1])T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]+Z$/.test(param_final_date)) {
*/
//if (param_final_date) {
    record.final_date = new Date(param_final_date);
}

//console.log("A");
//console.log(record);
/*VALORES PADROES*/
/*VALORES PADROES*/
/*VALORES PADROES*/




class App extends Component {
    render() {
        
        return (
            <>
            <CustomForm record={record}/>
            <br/>
            </>
        );
    }
}

export default App;


/*
import _ from 'lodash';


 function component() {
   const element = document.createElement('div');
  const btn = document.createElement('button');

   element.innerHTML = _.join(['Hello', 'webpack'], ' ');

  btn.innerHTML = 'Click me and check the console!';
  btn.onclick = renderLineChart;

  element.appendChild(btn);

   return element;
 }

 document.body.appendChild(component());
 document.body.appendChild(renderLineChart);
*/