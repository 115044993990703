import * as React from 'react';
import Box from '@mui/material/Box';
//import TextField from '@mui/material/TextField';

//import DatePicker from '@mui/lab/DatePicker';
import { MaskedValorTextField } from './valor_input_field.js';
import { IndiceSelectField } from './indice_input_field.js';
import { FormatedAreaChart } from './valor_corrigido_graph.js';
import { CustomAppBar } from './CustomAppBar.js';

import { CustomDatePicker  } from './CustomDatePicker.js';
import { CustomSubmitButton } from './CustomSubmitButton.js';
import { ValorCorrigidoFinal } from './ValorCorrigidoFinal.js';
import { CustomLinkButton } from './CustomLinkButton.js';
import { CustomShareButton } from './CustomShareButton.js';
import { CustomFooter } from './CustomFooter.js';


import DenseTable from './table';

import { Form } from 'react-final-form';

import taxas from './taxas.json';

import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';


let taxas_originais = JSON.parse(JSON.stringify(taxas));
const graph= {
  lines : [
      {
          datakey: "IPCA",
          name: "IPCA",
          color: '#8884d8'
      },
      {
          datakey: "IGPM",
          name: "IGPM",
          color: "#ff0000"
      },
      {
          datakey: "INPC",
          name: "INPC",
          color: '#8884d8'
      },
      {
          datakey: "SELIC",
          name: "SELIC",
          color: "#00ff00"
      },
      {
          datakey: "CDI",
          name: "CDI",
          color: "#0000ff"
      },
      {
          datakey: "TR",
          name: "TR",
          color: "#770000"
      },
  ],
  data : [...taxas]
};

var main_element = "";
var new_taxa = [];
var valor_atualizado_final = 0;
var taxa_acumulada_final = 0;
var valor_atualizado_inicial = 0;


const onSubmit = async values => {
  //console.log("VALORES");
  //console.log(values);
  main_element = values.taxa;
  new_taxa.length = 0;
  valor_atualizado_final = 0;
  taxa_acumulada_final = 0;
  var valor = values.value;
  var first_value = false;
  var firstDay = new Date(values.initial_date.getFullYear(), values.initial_date.getMonth(), 1);

  for (let i = 0; i < taxas_originais.length; i++) {
    var curr_date = new Date(taxas_originais[i].Data);
    //console.log("TESTE");
    //console.log(curr_date);
    //console.log(curr_date.getHours());
    //console.log(curr_date.getTimezoneOffset() / 60);
    //console.log(curr_date.getHours() + curr_date.getTimezoneOffset() / 60);
    //curr_date.setHours(curr_date.getHours() + curr_date.getTimezoneOffset() / 60);
    
    //console.log(taxas_originais[i].Data)
    //console.log('01-'+curr_date);
    //console.log(values.initial_date);
    //console.log("DATA");
    //console.log(curr_date);
    //console.log(values.final_date);
    if ((curr_date >= firstDay) && (curr_date <= values.final_date)) {
      //console.log("CURR_DATE");
      //console.log(firstDay);
      //console.log(firstDay.getTimezoneOffset());
      //console.log(firstDay.getTimezoneOffset().toString());
      //console.log(new Date(taxas_originais[i-1].Data));
      //console.log("TAXA");
      //console.log(taxas_originais[i][values.taxa]);
      //month = ;
      taxas_originais[i].data_formatada = ("0" + (curr_date.getMonth() + 1)).slice(-2) + "/" + curr_date.getFullYear();
      //console.log("ANO");
      //console.log(curr_date.getFullYear());

      if (!first_value) {
        //Se é o primeiro mês, no caso de atualização, tem q corrigir pelo valor do mês também
        first_value = true;
        if (taxas_originais[i][values.taxa] != "-") {
          taxas_originais[i].valor_correcao = parseFloat(valor) * parseFloat(taxas_originais[i][values.taxa])/100;
          taxas_originais[i].valor_final =  parseFloat(valor) + taxas_originais[i].valor_correcao;
         
        } else {
          taxas_originais[i].valor_correcao = 0;
          taxas_originais[i].valor_final = valor;
        }
        taxas_originais[i].valor=valor;

        taxa_acumulada_final = parseFloat(taxas_originais[i][values.taxa]);
      } else {
        if (taxas_originais[i][values.taxa] != "-") {
          taxas_originais[i].valor_correcao = taxas_originais[i-1].valor_final * parseFloat(taxas_originais[i][values.taxa])/100;
          taxas_originais[i].valor_final = taxas_originais[i-1].valor_final + taxas_originais[i].valor_correcao;
          taxa_acumulada_final = taxa_acumulada_final + parseFloat(taxas_originais[i][values.taxa]);
          
          

          taxas_originais[i].valor=taxas_originais[i-1].valor_final;
        } else {
          taxas_originais[i].valor=taxas_originais[i-1].valor_final;
          taxas_originais[i].valor_correcao=0;
          taxas_originais[i].valor_final=taxas_originais[i].valor;
        }
      }
      //console.log("INDICE");
      //console.log(taxas_originais[i][values.taxa]);
      //console.log("VALOR CORRECAO");
      //console.log(taxas_originais[i].valor_correcao);
      valor_atualizado_final = taxas_originais[i].valor_final;
      valor_atualizado_inicial = values.value;
      //console.log("DO PERIODO");
      //console.log('01-'+curr_date);
      new_taxa.push(taxas_originais[i]);
    }
    //graph.data[i].IPCA=100;
    //console.log(item);
  }
  //console.log("NEW_TAXA");
  //console.log(new_taxa)
  graph.data = new_taxa;



  //values.valor_corrigido=10000;
};




const loadInitialData = (record) => {

  //valor_corrigido:record.value, indice: record.taxa, data_inicio:record.initial_date, data_fim:record.final_date, valor_atualizado_final:record.valor_atualizado_final
  /*
              <MaskedValorTextField id="valor_corrigido" label="Valor" helperText="Valor a ser corrigido" />
            <IndiceSelectField id="indice" label="Índice" helperText="Índice de Correção"/>
            <CustomDatePicker id="data_inicio" label="Data Início" minDate={new Date('1980-01-01T05:00:00')} maxDate={new Date()} />
            <CustomDatePicker id="data_fim" label="Data Fim" minDate={new Date('1980-01-01T05:00:00')} maxDate={new Date()}/>
  */
  main_element = record.taxa;
  new_taxa.length = 0;
  valor_atualizado_final = 0;
  taxa_acumulada_final = 0;
  var valor = record.value;
  var first_value = false;
  var firstDay = new Date(record.initial_date.getFullYear(), record.initial_date.getMonth(), 1);

  for (let i = 0; i < taxas_originais.length; i++) {
    var curr_date = new Date(taxas_originais[i].Data);
    //console.log("TESTE");
    //console.log(curr_date);
    //console.log(record.final_date);
    //curr_date.setMinutes(curr_date.getMinutes() + curr_date.getTimezoneOffset());
    
    //console.log(taxas_originais[i].Data)
    //console.log('01-'+curr_date);
    //console.log(record.initial_date);
    if ((curr_date >= firstDay) && (curr_date <= record.final_date)) {
      //console.log("CURR_DATE");
      //console.log(firstDay);
      //console.log(firstDay.getTimezoneOffset());
      //console.log(firstDay.getTimezoneOffset().toString());
      //console.log(new Date(taxas_originais[i-1].Data));
      //console.log("TAXA");
      //console.log(taxas_originais[i][record.taxa]);
      //month = ;
      taxas_originais[i].data_formatada = ("0" + (curr_date.getMonth() + 1)).slice(-2) + "/" + curr_date.getFullYear();
      //console.log("ANO");
      //console.log(curr_date.getFullYear());

      if (!first_value) {
        //Se é o primeiro mês, no caso de atualização, tem q corrigir pelo valor do mês também
        first_value = true;
        if (taxas_originais[i][record.taxa] != "-") {
          taxas_originais[i].valor_correcao = parseFloat(valor) * parseFloat(taxas_originais[i][record.taxa])/100;
          taxas_originais[i].valor_final =  parseFloat(valor) + taxas_originais[i].valor_correcao;
          
        } else {
          taxas_originais[i].valor_correcao = 0;
          taxas_originais[i].valor_final = valor;
        }
        taxas_originais[i].valor=valor;

        taxa_acumulada_final = parseFloat(taxas_originais[i][record.taxa]);
      } else {
        if (taxas_originais[i][record.taxa] != "-") {
          taxas_originais[i].valor_correcao = taxas_originais[i-1].valor_final * parseFloat(taxas_originais[i][record.taxa])/100;
          taxas_originais[i].valor_final = taxas_originais[i-1].valor_final + taxas_originais[i].valor_correcao;
          taxa_acumulada_final = taxa_acumulada_final + parseFloat(taxas_originais[i][record.taxa]);
          
          

          taxas_originais[i].valor=taxas_originais[i-1].valor_final;
        } else {
          taxas_originais[i].valor=taxas_originais[i-1].valor_final;
          taxas_originais[i].valor_correcao=0;
          taxas_originais[i].valor_final=taxas_originais[i].valor;
        }
      }
      //console.log("INDICE");
      //console.log(taxas_originais[i][record.taxa]);
      //console.log("VALOR CORRECAO");
      //console.log(taxas_originais[i].valor_correcao);
      valor_atualizado_final = taxas_originais[i].valor_final;
      valor_atualizado_inicial = record.value;
      //console.log("DO PERIODO");
      //console.log('01-'+curr_date);
      new_taxa.push(taxas_originais[i]);
    }
    //graph.data[i].IPCA=100;
    //console.log(item);
  }
  //console.log("NEW_TAXA");
  //console.log(new_taxa)
  graph.data = new_taxa;

  return record;
}


export  const CustomForm = ({record, ...props}) => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      var UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  //console.log(taxas_originais[taxas_originais.length - 1].Data);


  return (
    <>
    <CustomAppBar/>
    <br/>
    <Form
      onSubmit={onSubmit}
      initialValues={loadInitialData(record)}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}  noValidate>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '50%',
              margin: '0 auto',
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
          >
            <MaskedValorTextField id="value" label="Valor" helperText="Valor a ser atualizado" />
            <IndiceSelectField id="taxa" label="Índice" helperText="Índice de Correção"/>
            <CustomDatePicker id="initial_date" label="Data Início" minDate={new Date('1980-01-01T05:00:00')} maxDate={new Date(taxas_originais[taxas_originais.length - 1].Data)} />
            <CustomDatePicker id="final_date" label="Data Fim" minDate={new Date('1980-01-01T05:00:00')} maxDate={new Date(taxas_originais[taxas_originais.length - 1].Data)}/>
          </Box>
          <CustomSubmitButton />
          <br/>
          <Divider/>
          <Box>
            <center>
                <ValorCorrigidoFinal valor_inicial={valor_atualizado_inicial} valor={valor_atualizado_final} />
                {hasTouchScreen
                  ? <CustomShareButton valor={values.value} valor_atualizado_final={valor_atualizado_final} taxa={values.taxa} initial_date={values.initial_date} final_date={values.final_date}/>
                  : <CustomLinkButton valor={values.value} valor_atualizado_final={valor_atualizado_final} taxa={values.taxa} initial_date={values.initial_date} final_date={values.final_date}/>
                }
            </center>
            <br/>
          </Box>
          <Divider/>
          <br/>
          <center>
            <Typography variant="h5" color="text.secondary">
              Gráfico do Período ({main_element})
            </Typography>
          </center>
          <br/>

          <FormatedAreaChart record={values} graph={graph} main_element={main_element}/>
          <br/>
          <Divider/>
          <br/>
          <center>
            <Typography variant="h5" color="text.secondary">
              Tabela de Correção ({main_element})
            </Typography>
          </center>
          <br/>
          <DenseTable valores={graph.data} indice={main_element}/>
          <br/>
          <br/>
          <CustomFooter/>
          </form>
      )}
    />
    </>
  );
};






