import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  TextField,
  DatePicker,
} from 'mui-rff';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

export const CustomDatePicker = (props) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
        <DatePicker
          locale={ptBR}
          views={['year', 'month', 'day']}
          name={props.id}
          minDate={props.minDate}
          maxDate={props.maxDate}
          disableFuture
          label={props.label}
          size="small"
          renderInput={(params) => <TextField name={props.id} {...params} size="small" />}
        />
    </LocalizationProvider>
  );
}
  