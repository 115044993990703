import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



function createData(numero, mes, valor, indice, valor_correcao, valor_final) {
    return { numero, mes, valor, indice, valor_correcao, valor_final};
}
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];
  
  export default function DenseTable({valores, indice}) {
      if (! indice) {
        return false;
      }
      //console.log(valores);
      //console.log("INDICE");
      //console.log(indice);
    
    return (
      <center>
      <TableContainer sx={{ minWidth: 200, maxWidth:900 }} component={Paper}>
        <Table stickyHeader  sx={{ minWidth: 200, maxWidth:900 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nº</TableCell>
              <TableCell align="left">Mês/Ano</TableCell>
              <TableCell align="center">Índice&nbsp;(%)</TableCell>
              <TableCell align="left">Correção</TableCell>
              <TableCell align="left">Valor Atualizado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {valores.map((row, key) => (
              <StyledTableRow 
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">{key}</TableCell>
                <TableCell align="left">{row.data_formatada}</TableCell>
                <TableCell align="center">{row[indice].toString().replace(".", ",")}</TableCell>
                <TableCell align="left">R$ {parseFloat(row.valor_correcao).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</TableCell>
                <TableCell align="left">R$ {parseFloat(row.valor_final).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</TableCell>
              </StyledTableRow >
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </center>
    );
  }