import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export const ValorCorrigidoFinal = ({valor, valor_inicial, taxa}) => {
  const alteracao_porcentagem = (valor - valor_inicial) / valor_inicial * 100;
  return (
  <Card sx={{ minWidth: 275 }} style={{ border: "none", boxShadow: "none" }}>
  <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      Valor Corrigido
    </Typography>
  <Typography variant="h4" component="div">R$ {parseFloat(valor).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} <br/> ({parseFloat(alteracao_porcentagem).toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} %)</Typography>
  </CardContent>
  </Card>
  );

};