import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { CustomInitialInfoModal } from './CustomInitialInfoModal.js';

/*
<br/>
   
<CustomInitialInfo/>

<Divider />
<br/>
<br/>
*/

export const CustomAppBar = () => {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Atualizar Valor
          </Typography>
          <CustomInitialInfoModal/>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
  