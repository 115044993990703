import * as React from 'react';
//import TextField from '@mui/material/TextField';
import {
  Select
} from 'mui-rff';

import MenuItem from '@mui/material/MenuItem';

const indices = [
  {
    value: 'IPCA',
    label: 'IPCA',
  },
  {
    value: 'IGPM',
    label: 'IGP-M',
  },
  {
    value: 'INPC',
    label: 'INPC',
  },
  {
    value: 'SELIC',
    label: 'SELIC',
  },
  {
    value: 'CDI',
    label: 'CDI',
  },
  {
    value: 'TR',
    label: 'TR',
  }
];




export const IndiceSelectField = (props) => {
  return (
    <Select
      label={props.label}
      variant="outlined" 
      id={props.id}
      name={props.id}
      size="small"
      helperText={props.helperText}
    >
      {indices.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}